import { makeStyles } from "@material-ui/core";
import { loadingViewComponent } from "app/main/common/LoadingView";
import SmartHopMap from "app/main/profile/trips/SmartHopMap";
import { parseRolesInfo } from "app/main/utils/rolesUtils";
import { showErrorSnackbar } from "app/main/utils/snackbarUtil";
import { toStateViews } from "app/main/utils/stateUtils";
import { getTrucksInformation } from "app/services/truckServices";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SearchPanel from "../SearchPanel";

const useStyles = makeStyles(() => ({
	map: {
		height: "50vh",
	},
}));

function SearchRequestView({ dataIds, onProgress, onDone, onMinimize, setTitle }) {
	useEffect(() => {
		setTitle?.("Search");
		// eslint-disable-next-line
	}, []);
	const snackbar = useSnackbar();
	const { carrierId, truckId, hasAllFilters, searchFilters } = dataIds;
	const [carrierState, setCarrierState] = useState(carrierId);
	const [truckState, setTruckState] = useState(truckId);

	const classes = useStyles();
	const [model, setModel] = useState(null);
	const [dataMap, setDataMap] = useState(null);
	const user = useSelector(({ auth }) => auth.user);
	const roleStatus = parseRolesInfo(["driver"], user);
	const isNativeEnv = window?.location?.href?.includes("/native/");

	useEffect(() => {
		if (!truckState) return;

		if (hasAllFilters) {
			async function fetchTruckInformation() {
				try {
					let pickupAfter = searchFilters?.pickup_ds__after;
					let truckInformation = await getTrucksInformation({
						options_carrier_id_search: carrierState,
						options_truck_ids_search: truckState,
						options_metadata_truck_plan: true,
					});
					const location_origin__view = searchFilters?.location_origin
						? { value: searchFilters.location_origin, label: searchFilters.location_origin }
						: null;

					let searchData = {
						...searchFilters,
						location_origin__view,
						pickup_ds__after: moment(pickupAfter).isBefore(moment(), "date") ? moment().toISOString() : pickupAfter,
						truck__view: truckInformation?.items?.filter((truck) => truck._id.toString() === truckState)[0]
							?.truck__view,
					};
					if (!isNativeEnv) {
						const dataMapModel = {
							equipment: searchFilters.equipment,
							locations: [
								{ location: searchFilters.location_origin },
								...(searchFilters?.location_destination ? [{ location: searchFilters?.location_destination }] : []),
							],
							origin: searchFilters?.location_origin,
							destination: searchFilters?.location_destination,
							activeLocations: true,
							activeHeatMap: !roleStatus?.visible,
						};
						setDataMap(dataMapModel);
					}
					setModel(searchData);
				} catch (error) {
					showErrorSnackbar(snackbar, error);
					onProgress(false);
					onDone?.(true);
				}
			}

			fetchTruckInformation();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!carrierState || !truckState || hasAllFilters) return;

		async function fetchTruckAndTruckPlan() {
			try {
				const loadDefaultData = !!searchFilters && !hasAllFilters;
				let truckInformation = await getTrucksInformation({
					options_active_only: true,
					options_carrier_id_search: carrierState,
					options_truck_ids_search: truckState,
					options_search_truck_plan: !loadDefaultData,
					options_metadata_truck_plan: true,
				});
				truckInformation = truckInformation?.items?.filter((truck) => truck._id.toString() === truckState);
				truckInformation = truckInformation[0];
				const currentTruckPlan = truckInformation?.plan__view?.metadata;
				const truckInfo = truckInformation?.truck__view?.metadata;
				const origin = searchFilters?.location_origin
					? searchFilters?.location_origin
					: currentTruckPlan?.current_location;
				const destination = searchFilters?.location_destination
					? searchFilters?.location_destination
					: currentTruckPlan?.current_destination;
				const destinationStates = searchFilters?.destinationStates
					? searchFilters.destinationStates
					: currentTruckPlan?.destinationStates;

				// Improvement Date
				const pickupAfter = searchFilters?.pickup_after
					? searchFilters?.pickup_after
					: currentTruckPlan?.current_available_date;
				const utcOffset = moment(pickupAfter).utcOffset();
				const pickupAfterDiff = moment(pickupAfter).subtract(utcOffset, "minutes");
				const searchModel = {
					dh_origin: currentTruckPlan?.dh_origin_preferred > 0 ? currentTruckPlan?.dh_origin_preferred : 250,
					dh_destination:
						currentTruckPlan?.dh_destination_preferred > 0 ? currentTruckPlan?.dh_destination_preferred : 250,
					max_weight: loadDefaultData ? truckInfo?.max_weight : currentTruckPlan?.max_weight ?? truckInfo?.max_weight,
					...(!loadDefaultData && currentTruckPlan?.delivery_date_before
						? { delivery_ds__before: currentTruckPlan?.delivery_date_before }
						: {}),
					...(!loadDefaultData && currentTruckPlan?.driver_weekly_hos
						? { driver_weekly_hos: currentTruckPlan?.driver_weekly_hos }
						: {}),
					rate_per_mile_preferred:
						currentTruckPlan?.rate_per_mile_preferred > 0 ? currentTruckPlan?.rate_per_mile_preferred : 0,
					...(currentTruckPlan?.profit_preferred !== undefined
						? { profit_preferred: currentTruckPlan?.profit_preferred }
						: {}),
					...(currentTruckPlan?.min_rank_mci ? { min_rank_mci: currentTruckPlan?.min_rank_mci } : {}),
					rate_total_preferred: currentTruckPlan?.rate_total_preferred > 0 ? currentTruckPlan?.rate_total_preferred : 0,
					miles_preferred__min: currentTruckPlan?.miles_preferred__min > 0 ? currentTruckPlan?.miles_preferred__min : 0,
					miles_preferred__max:
						currentTruckPlan?.miles_preferred__max > 0 ? currentTruckPlan?.miles_preferred__max : 4000,
					carrier__view: truckInformation?.carrier__view,
					truck__view: truckInformation?.truck__view,
					driver__view: truckInformation?.driver__view,
					carrier: carrierState,
					truck: truckState,
					driver: truckInformation?.driver,
					blacklisted_states_ids: truckInformation?.driver__view?.metadata?.blacklisted_states_ids,
					blacklisted_states_ids__view: toStateViews(truckInformation?.driver__view?.metadata?.blacklisted_states_ids),
					equipment: truckInfo?.equipment,
					trailer: truckInfo?.trailer,
					droptrailer: {
						value: Number(truckInfo?.trailer?.slice(0, 2)),
						label: truckInfo?.trailer,
					},
					location_origin: origin ?? null,
					...(origin
						? {
								location_origin__view: {
									value: origin,
									label: origin,
								},
						  }
						: {}),
					destinationStates: destinationStates,
					destinationStates__view: toStateViews(destinationStates),
					pickup_ds__after: moment(pickupAfter).isBefore(moment(), "date")
						? moment().toISOString()
						: moment(pickupAfterDiff).toISOString(),
					order_by: loadDefaultData ? "MATCH" : searchFilters?.order_by ?? "MATCH",
					remainingDriveTime: currentTruckPlan?.remainingDriveTime,
					remainingCycleDutyTime: currentTruckPlan?.remainingCycleDutyTime,
				};

				if (!(destinationStates?.length > 0)) {
					searchModel.location_destination = destination;
					searchModel.location_destination__view = {
						value: destination,
						label: destination,
					};
				}

				if (!isNativeEnv) {
					const dataMapModel = {
						locations: [{ location: origin }, ...(destination ? [{ location: destination }] : [])],
						origin,
						destination,
						activeHeatMap: !roleStatus?.visible,
						equipment: truckInformation?.equipment,
						activeLocations: true,
					};
					setDataMap(dataMapModel);
				}
				setModel(searchModel);
				onProgress(false);
			} catch (error) {
				showErrorSnackbar(snackbar, error);
				onProgress(false);
				onDone?.(true);
			}
		}

		fetchTruckAndTruckPlan();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [carrierState, truckState]);

	if (!dataMap && !model) {
		return loadingViewComponent();
	}
	return (
		<>
			{dataMap && (
				<div className={classes.map + " w-full"}>
					<SmartHopMap
						provider="TRIMBLE MAPS"
						classes={{ root: "w-full max-h-full max-w-full md:w-screen h-screen" }}
						locations={dataMap?.locations}
						origin={dataMap?.origin}
						destination={dataMap?.destination}
						loadId={dataMap.load_id}
						weight={dataMap.weight}
						activeLocations={dataMap?.activeLocations}
						activeHeatMap={dataMap.equipment !== "PO" ? dataMap.activeHeatMap : false}
						equipment={dataMap.equipment}
						map="myMapSuggest"
					/>
				</div>
			)}
			{model && (
				<SearchPanel
					type="request"
					submitRedirectURL="/search"
					dataIds={dataIds}
					model={model}
					onChangeCommitted={(newModel, key) => {
						if (key === "carrier") {
							setCarrierState(newModel.carrier);
							setTruckState(null);
							setModel({});
						} else if (key === "truck") {
							setTruckState(newModel?.truck);
						} else if (["location_destination", "location_origin"].includes(key)) {
							setModel({
								...model,
								location_origin: newModel?.location_origin,
								location_origin__view: {
									value: newModel?.location_origin,
									label: newModel?.location_origin,
								},
								location_destination: newModel?.location_destination,
								location_destination__view: {
									value: newModel?.location_destination,
									label: newModel?.location_destination,
								},
							});
							if (!isNativeEnv) {
								setDataMap({
									origin: newModel?.location_origin,
									destination: newModel?.location_destination,
									locations: [{ location: newModel?.location_origin }, { location: newModel?.location_destination }],
									activeHeatMap: !roleStatus?.visible,
									activeLocations: true,
									equipment: newModel?.equipment,
								});
							}
						} else {
							setModel(newModel);
						}
					}}
					closeFormDialog={() => {
						onDone?.(true);
						onMinimize?.(true);
					}}
				/>
			)}
		</>
	);
}

export default SearchRequestView;
