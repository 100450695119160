import moment from "moment";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useMemo, useState } from "react";
import { Typography, Tooltip, Icon } from "@material-ui/core";
import { formatNumber } from "app/main/utils/tableUtils";
import SmarthopTableView from "@smarthop/list/SmarthopTableView";
import { isNumber } from "lodash";
import { CellTypes } from "@smarthop/list/SmarthopCellTypes";
import { sortArray, formatCurrency } from "app/main/utils/tableUtils";
import { parseTimeFrameOverrides } from "../static/WidgetUtils";

const _COLORS_TEXT = ["text-green-700", "text-blue-700", "text-orange-700", "text-purple-700"];

const _COLORS_MAP = {
	PRIMARY: "#062246",
	SECONDARY: "#E7683D",
	GREEN: "#4CAF50",
	ORANGE: "#FF9800",
	BLUE: "#2196F3",
	PINK: "#E91E63",
	BROWN: "#795548",
	AMBER: "#FFC107",
	CYAN: "#00BCD4",
	INDIGO: "#3F51B5",
	PURPLE: "#AB47BC",
	LIME: "#AFB42B",
	RED: "#F44336",
};

const _COLORS_FUNTION = {
	POSITIVE_GREEN__NEGATIVE_RED: (item, f) => {
		return item?.value > 0 ? _COLORS_MAP.GREEN : _COLORS_MAP.RED;
	},
};

const _LABEL_COLORS = {
	REEFER: _COLORS_MAP.BLUE,
	VAN: _COLORS_MAP.PURPLE,
	PO: _COLORS_MAP.BROWN,
	FLATBED: _COLORS_MAP.ORANGE,
	EXPENSES: _COLORS_MAP.ORANGE,
	REVENUE: _COLORS_MAP.GREEN,
	GROWTH: _COLORS_MAP.GREEN,
	DIGITAL: _COLORS_MAP.PURPLE,
	MANUAL: _COLORS_MAP.CYAN,
};

const _ADDONS_COLORS_MAP = {
	CARRIER_PROJECTED_EXPENSES: _COLORS_MAP.RED,
	CARRIER_VAN_GOAL_REVENUE: _COLORS_MAP.GREEN,
	CARRIER_REEFER_GOAL_REVENUE: _COLORS_MAP.LIME,
	CARRIER_VAN_GOAL_RPM: _COLORS_MAP.GREEN,
	CARRIER_REEFER_GOAL_RPM: _COLORS_MAP.LIME,
	CARRIER_FLATBED_GOAL_REVENUE: _COLORS_MAP.CYAN,
	CARRIER_FLATBED_GOAL_RPM: _COLORS_MAP.BROWN,
	CARRIER_PO_GOAL_RPM: _COLORS_MAP.PINK,
	CARRIER_PO_GOAL_REVENUE: _COLORS_MAP.INDIGO,
	CARRIER_GOAL_REVENUE: _COLORS_MAP.PURPLE,
	CARRIER_GOAL_RPM: _COLORS_MAP.ORANGE,
};

const _DEFAULT_COLORS = [
	_COLORS_MAP.BLUE,
	_COLORS_MAP.GREEN,
	_COLORS_MAP.PURPLE,
	_COLORS_MAP.ORANGE,
	_COLORS_MAP.INDIGO,
	_COLORS_MAP.LIME,
	_COLORS_MAP.PINK,
	_COLORS_MAP.BROWN,
	_COLORS_MAP.AMBER,
	_COLORS_MAP.CYAN,
];

export function WidgetGraph(props) {
	const data = props.data;
	const visuals = props.visuals;
	const config = props.config ?? {};
	const categories = data?.categories;
	const classes = props.classes;
	const labels = props.labels;
	const secondaryCategories = data?.secondaryCategories;
	const refWidgetDataTable = props.refWidgetDataTable;
	const overridesToApply = useMemo(
		() => parseTimeFrameOverrides(props?.config?.overrides, props?.config?.aggregationWindowMain),
		[props?.config]
	);
	const graphType = useMemo(() => {
		let type = props?.config?.graphType;
		overridesToApply.forEach((o) => {
			switch (o.field) {
				case "GRAPH_TYPE":
					type = o.graphType ? o.graphType : type;
					break;
				default:
					break;
			}
		});

		return type;
	}, [overridesToApply, props?.config]);

	const chartType = graphType ? graphType.toLowerCase() : "bar";
	const chartUnit = config?.graphUnit ? config.graphUnit.toLowerCase() : "default";
	const chartDateType = config?.aggregationPeriod;
	const readOnly = config?.readOnly;
	const aggregationIgnoreFutureData = config?.aggregationIgnoreFutureData;
	const chartStroke = { curve: "smooth" };
	const chartXTickAmount = 10;
	const chartYTickAmount = chartType === "bar" ? 6 : 7;
	const [sortable, setSortable] = useState({});

	const labelFomatter = (y) => {
		return valueFomatter(y);
	};

	const valueFomatter = (y, unit) => {
		let decimals = 0;
		let prefix = "";
		let postfix = "";

		if (!unit) {
			unit = chartUnit;
		}

		if (unit === "currency") {
			return formatCurrency(y);
		} else if (unit === "miles") {
			postfix = " mi";
		} else if (unit === "ms") {
			postfix = " ms";
		} else if (unit === "persents") {
			postfix = "%";
			y = y * 100;
			if (y !== parseInt(y)) {
				decimals = y < 10 && y > -10 ? 1 : 0;
			}
		} else {
			if (y !== parseInt(y)) {
				decimals = y < 1 && y > -1 ? 2 : y < 10 && y > -10 ? 1 : 0;
			}
		}

		return formatNumber(y, decimals, prefix, postfix);
	};

	const dateFormatter = (date, short) => {
		if (!date?.length) {
			return "";
		}

		if (chartDateType === "YEAR") {
			return date;
		} else if (chartDateType === "MONTH") {
			return moment(date).format(short ? "MMM" : "MMMM");
		} else if (chartDateType === "DAY") {
			return moment(date).format("MMM DD");
		} else {
			return date;
		}
	};

	const dateFormatterRange = (date1, date2, short) => {
		const m1 = moment(date1);
		const m2 = moment(date2);

		const ms = moment(m1).startOf("month");
		const me = moment(m1).endOf("month");

		if (ms.isSame(m1, "day") && me.isSame(m2, "day")) {
			return moment(m1).format(short ? "MMM" : "MMMM");
		} else {
			return dateFormatter(date1, short) + " - " + dateFormatter(date2, short);
		}
	};

	const { series, hasPrimaryDataPoints, cutOffIndex, addons } = useMemo(() => {
		const hasPrimaryDataPoints = data?.series?.find((item) => !item.metadata.type || item.metadata.type === "MAIN");

		let copy;
		let addons;
		let cutOffIndex = -1;
		if (aggregationIgnoreFutureData) {
			let now = moment().add(1, "day");
			categories?.forEach((date, i) => {
				if (cutOffIndex === -1 && now < moment(date)) {
					cutOffIndex = i;
				}
			});

			if (cutOffIndex !== -1) {
				copy = [];
				data?.series?.forEach((item) => {
					const processed = { ...item };
					for (let i = cutOffIndex; i < processed.data?.length; i++) {
						processed.data[i] = null;
					}

					copy.push(processed);
				});
			}
		}

		if (!copy) {
			copy = [...data?.series];
		}

		if (data?.addons?.length) {
			addons = {};
			// Presetting type
			copy.forEach((item) => {
				item.type = chartType;
			});
			// Adding addons values
			data?.addons?.forEach?.((addon, i) => {
				const addonSeries = {
					key: addon.config.type,
					data: categories.map(() => addon.value),
					name: addon.name,
					type: "line",
					addon: true,
				};
				copy.push(addonSeries);
				addons[copy.length - 1] = {
					config: addon.config,
					name: addon.name,
					type: "line",
					seriesIndex: copy.length - 1,
					visibleIndex: i * 5 + 3,
				};
			});
		}

		return { series: copy, hasPrimaryDataPoints, cutOffIndex, addons };
		// eslint-disable-next-line
	}, [categories, data]);

	const timeLabel = useMemo(() => {
		if (categories?.[0] === "__ALL_TIME__") {
			return "All Time";
		}
		let lastSecondaryDate = "";
		secondaryCategories?.forEach((date) => {
			if (date.length > 0) lastSecondaryDate = date;
		});

		const secondary =
			secondaryCategories?.length > 0
				? secondaryCategories.length === 1
					? dateFormatter(secondaryCategories[0])
					: dateFormatterRange(secondaryCategories[0], lastSecondaryDate)
				: null;

		const primary =
			categories?.length > 0
				? categories.length === 1
					? dateFormatter(categories[0])
					: dateFormatterRange(categories[0], categories[categories.length - 1])
				: null;

		return !primary ? null : secondary ? secondary + " vs " + primary : primary;
		// eslint-disable-next-line
	}, [categories, secondaryCategories]);

	const categoriesLabels = useMemo(() => {
		if (categories?.[0] === "__ALL_TIME__") {
			return [""];
		}
		return categories?.length === 1
			? secondaryCategories?.length > 0
				? [dateFormatter(secondaryCategories[0]) + " vs " + dateFormatter(categories[0])]
				: [dateFormatter(categories[0])]
			: categories?.map((date, i) =>
					secondaryCategories?.length > 0
						? [
								dateFormatter(date, true),
								secondaryCategories[i]?.length > 0 ? "(" + dateFormatter(secondaryCategories[i], true) + ")" : "",
						  ]
						: dateFormatter(date, true)
			  );
		// eslint-disable-next-line
	}, [categories, secondaryCategories]);

	const customColors = useMemo(() => {
		let customColors = [];
		let hasCustomization;
		series?.forEach((item, i) => {
			if (_COLORS_FUNTION[item.metadata?.color]) {
				hasCustomization = true;
				customColors.push(_COLORS_FUNTION[item.metadata?.color]);
			} else if (_COLORS_MAP[item.metadata?.color]?.startsWith("#")) {
				hasCustomization = true;
				customColors.push(_COLORS_MAP[item.metadata?.color]);
			} else if (_LABEL_COLORS[item.name?.toUpperCase()]?.startsWith("#")) {
				hasCustomization = true;
				customColors.push(_LABEL_COLORS[item.name?.toUpperCase()]);
			} else {
				customColors.push(_DEFAULT_COLORS[i % _DEFAULT_COLORS.length]);
			}
		});

		if (hasCustomization) {
			return customColors;
		} else {
			return undefined;
		}
		// eslint-disable-next-line
	}, [series]);

	const tableData = useMemo(() => {
		if (chartType === "table" && series) {
			if (config?.aggregationPeriod !== "ALL") {
				return { error: `Table View 'Group Period By' only supports 'ALL' value` };
			}
			if (secondaryCategories?.length > 0) {
				return { error: `Table does not support any 'Secondary Time Window' value` };
			}

			const metricsAggrByOwnerCount = config?.metrics?.filter(
				(metric) => !!metric?.aggregationFields?.find((field) => field.includes("1__"))
			).length;
			const metricsAggrByEntityCount = config?.metrics?.filter(
				(metric) => !!metric?.aggregationFields?.find((field) => field.includes("2__"))
			).length;
			const metricsAggrByLinkCount = config?.metrics?.filter(
				(metric) =>
					!!metric?.aggregationFields?.find((field) => field.includes("4__")) ||
					// WORKAROUND: no every metrick is using links for now, it's ok to provide field in entity fields
					!!metric?.aggregationFields?.find((field) => field.includes("2__"))
			).length;

			if (!config?.graphTableMainColumn) {
				return { error: `Table View requires 'Primary Column' value` };
			} else if (config?.graphTableMainColumn === "OWNER" && metricsAggrByOwnerCount !== config?.metrics?.length) {
				return { error: `Not every metric is aggregated by 'Owner' field` };
			} else if (config?.graphTableMainColumn === "ENTITY" && metricsAggrByEntityCount !== config?.metrics?.length) {
				return { error: `Not every metric is aggregated by 'Entity' field` };
			} else if (config?.graphTableMainColumn === "LINK" && metricsAggrByLinkCount !== config?.metrics?.length) {
				return { error: `Not every metric is aggregated by 'Link' field` };
			}

			const mainColumnField = config?.graphTableMainColumn.toLowerCase();
			const tableIdField = mainColumnField;

			const countWithoutIds = series.filter((item) =>
				config?.graphTableMainColumn !== "LINK"
					? !item.metadata[mainColumnField] || !item.metadata[mainColumnField + "__view"]
					: item.metadata[mainColumnField + "s"]?.[0]
					? !item.metadata[mainColumnField + "s"]?.[0] || !item.metadata[mainColumnField + "s__views"]?.[0]
					: // WORKAROUND: no every metrick is using links for now, it's ok to provide field entity instead as backup
					  !item.metadata["entity"] || !item.metadata["entity__view"]
			).length;
			if (countWithoutIds > 0) {
				return { error: `Result contains ${countWithoutIds} data points without '${mainColumnField}' field` };
			}

			const countInvalidDataCount = series.filter((item) => item.data?.length !== 1).length;
			if (countInvalidDataCount > 0) {
				return { error: `Result contains ${countWithoutIds} data points with invalid count of values` };
			}

			const set = {};
			const columns = {};

			const primarySeries = series?.filter((item) => item.metadata?.type !== "SECONDARY");
			const secondarySeries = series?.filter((item) => item.metadata?.type === "SECONDARY");

			primarySeries.forEach((item) => {
				const metricLabel = item.metadata.metricLabel;
				const metricKey = metricLabel.replace(/ /g, "_").toUpperCase();
				const metricAggregator = item.metadata.aggregator;
				const metricParams = item.metadata.params?.join("__") ?? "NO_PARAMS";
				const dataKey = metricKey + "__" + metricParams + "__" + metricAggregator;
				const dataLabel =
					metricLabel + (item.metadata.params?.length > 0 ? " (" + item.metadata.params?.join(", ") + ")" : "");
				const mainColumnId =
					config?.graphTableMainColumn !== "LINK"
						? item.metadata[mainColumnField]
						: // WORKAROUND: if link is not provided, looking into entity
						  item.metadata[mainColumnField + "s"]?.[0] ?? item.metadata["entity"];
				const mainColumnView =
					config?.graphTableMainColumn !== "LINK"
						? item.metadata[mainColumnField + "__view"]
						: // WORKAROUND: if link is not provided, looking into entity
						  item.metadata[mainColumnField + "s__views"]?.[0] ?? item.metadata["entity__view"];

				if (!set[mainColumnId]) {
					set[mainColumnId] = {
						main: mainColumnId,
						main__view: mainColumnView,
					};

					if (!columns.main__view) {
						const viewType = mainColumnView._view_type;
						const label =
							viewType === "carrier__view"
								? "Carrier"
								: viewType === "truck__view"
								? "Truck"
								: viewType === "user__view"
								? "User"
								: viewType === "profile__view"
								? "User"
								: viewType === "payee__view"
								? "Payee"
								: "Data";
						columns.main__view = {
							key: "main__view",
							label: label,
							type: viewType,
							viewConfig: {
								showMcNumber: viewType === "carrier__view" ? true : false,
								showId: false,
								showEmail: viewType === "profile__view" ? true : false,
								showPayeeType: viewType === "payee__view" ? true : false,
								showStatus__top: viewType === "payee__view" ? true : false,
								readOnly,
							},
						};
					}
				}

				const metricEntity = item.metadata.entity ?? item.metadata?.links?.[0];
				const secondaryItem = secondarySeries?.find(
					(item) =>
						item.metadata?.metricLabel === metricLabel &&
						((item.metadata?.entity && item.metadata?.entity === metricEntity) ||
							(item.metadata?.links?.[0] && item.metadata?.links?.[0] === metricEntity))
				);

				const primaryValue = item.data[0];
				const secondaryValue = secondaryItem?.data?.[0];
				const diffValue =
					isNumber(primaryValue) && isNumber(secondaryValue)
						? parseInt((primaryValue - secondaryValue) * 1000) / 1000
						: null;
				const diffPercentage = isNumber(diffValue)
					? diffValue === 0
						? 0
						: parseInt((secondaryValue < 0 ? (-1 * diffValue) / secondaryValue : diffValue / secondaryValue) * 1000) /
						  1000
					: null;

				let unit = item.metadata.unit?.toLowerCase();
				set[mainColumnId][dataKey] = {
					primaryValue: isNumber(primaryValue) ? valueFomatter(primaryValue, unit) : null,
					originalPrimaryValue: primaryValue,
					secondaryValue: isNumber(secondaryValue) ? valueFomatter(secondaryValue, unit) : null,
					diffValue: !isNaN(diffValue) && isNumber(diffValue) ? valueFomatter(diffValue, unit) : null,
					diffPercentage:
						!isNaN(diffPercentage) && isNumber(diffPercentage) ? valueFomatter(diffPercentage, "persents") : null,
					signal: diffPercentage > 0 ? "GOOD" : diffPercentage < 0 ? "BAD" : null,
					arrow: diffPercentage > 0 ? "UP" : diffPercentage < 0 ? "DOWN" : null,
				};
				if (!columns[dataKey]) {
					columns[dataKey] = {
						key: dataKey,
						type: isNumber(secondaryValue) ? "component" : "text",
						table: { align: "right" },
						label: dataLabel,
						builder: (item) => {
							return !isNumber(secondaryValue) ? (
								item[dataKey]?.primaryValue
							) : (
								<div key={"label_"} className={"flex flex-row w-full items-center justify-end "}>
									<Typography className={"text-right whitespace-nowrap "}>{item[dataKey]?.primaryValue}</Typography>
									<Tooltip
										title={
											<Typography className={"text-white"}>
												{!item[dataKey]?.diffValue
													? `No previous period data to comapre with`
													: `Comparing to previous period amout of ${item[dataKey]?.secondaryValue}, where difference is ${item[dataKey]?.diffValue}`}
											</Typography>
										}
										placement="top"
									>
										<div className="flex flex-row items-center justify-end  ml-8">
											<Typography
												className={
													"min-w-32 text-right " +
													(item[dataKey]?.signal === "GOOD"
														? " text-green-700 "
														: item[dataKey]?.signal === "BAD"
														? " text-red-700 "
														: " text-grey-500 ")
												}
											>
												{item[dataKey]?.diffPercentage ?? "-"}
											</Typography>
											<Icon
												className={
													"font-bold ml-1 text-14 " +
													(item[dataKey]?.signal === "GOOD"
														? " text-green-600 "
														: item[dataKey]?.signal === "BAD"
														? " text-red-600 "
														: " text-grey-400 ")
												}
											>
												{item[dataKey]?.arrow === "UP" ? "north" : item[dataKey]?.arrow === "DOWN" ? "south" : ""}
											</Icon>
										</div>
									</Tooltip>
								</div>
							);
						},
					};
				}
			});

			const tableItems = Object.keys(set).map((key) => set[key]);
			const tableColumns = Object.keys(columns).map((key) => columns[key]);
			let listOfItemSortable = [];
			if (config?.sortable) {
				listOfItemSortable = tableColumns.map((item) => item?.key);
			}

			return {
				contentIdKey: tableIdField,
				data: { items: tableItems },
				content: {
					table: { variant: "skinny", orderBy: listOfItemSortable },
					items: tableColumns,
				},
			};
		}
		// eslint-disable-next-line
	}, [chartType, config, series]);

	const heatmapData = useMemo(() => {
		if (chartType === "heatmap" && series) {
			if (secondaryCategories?.length > 0) {
				return { error: `Heatmap does not support any 'Secondary Time Window' value` };
			}

			let views = [];
			series.forEach((item) => {
				if (item.metadata?.entity__view) {
					views = [
						{ name: item.name, entity: item.metadata?.entity, entity__view: item.metadata?.entity__view },
						...views,
					];
				} else if (item.metadata?.links__views?.[0]) {
					views = [
						{ name: item.name, entity: item.metadata?.links?.[0], entity__view: item.metadata?.links__views?.[0] },
						...views,
					];
				}
			});
			if (views?.length !== series?.length) {
				return {};
			}
			return {
				views,
			};
		}
		// eslint-disable-next-line
	}, [chartType, config, series]);

	const renderErrorView = (error) => {
		return (
			<div className={"flex flex-col w-full h-full"}>
				<div className={"flex flex-col w-full h-full justify-center items-center"}>
					<Typography className="text-13 text-red-600">{error}</Typography>
				</div>
			</div>
		);
	};

	if (!["bar", "area", "donut", "last_value", "table", "heatmap"].includes(chartType)) {
		return renderErrorView(`No Supported Graph Type '${chartType?.toUpperCase()}'`);
	}
	if (chartType === "table" && !!tableData?.error) {
		return renderErrorView(tableData.error);
	}

	const flatCategories = [];
	const flatSeries = [];
	if (categories && series && chartType === "donut") {
		series?.forEach((item) => {
			item.data.forEach((datapoint, i) => {
				const period = categories[i];
				const name = item.name;
				const label = categories.length > 1 ? period + ", " + name : name;
				flatCategories.push(label);
				flatSeries.push(datapoint);
			});
		});
	}

	const labelChartType = config?.graphLabelStyle ? config.graphLabelStyle.toLowerCase() : "default";
	const lastValues = [];
	let labelClasses = "text-14 md:text-20";
	let labelHasComparison = false;

	if (categories && series && chartType === "last_value") {
		if (labelChartType === "flat") {
			const primarySeries = series?.filter((item) => item.metadata?.type !== "SECONDARY");
			const secondarySeries = series?.filter((item) => item.metadata?.type === "SECONDARY");

			primarySeries?.forEach((primaryItem) => {
				const dataKey = primaryItem.key;
				const secondaryItem = secondarySeries?.find((sitem) => !!dataKey && sitem?.key === dataKey);

				const primaryValue = primaryItem.data?.length > 0 ? primaryItem.data[primaryItem.data.length - 1] : null;
				const secondaryValue =
					secondaryItem?.data?.length > 0 ? secondaryItem.data[secondaryItem.data.length - 1] : null;

				let namePrimary = primaryItem.metadata?.metricLabel;
				if (!namePrimary?.length) namePrimary = primaryItem.name;
				if (!namePrimary?.length) namePrimary = dataKey;

				let nameSecondary = secondaryItem ? secondaryItem?.metadata?.metricLabel : null;
				if (secondaryItem && !nameSecondary?.length) nameSecondary = secondaryItem?.name;
				if (secondaryItem && !nameSecondary?.length) nameSecondary = dataKey;

				let cleanupName;
				if (namePrimary && namePrimary !== nameSecondary) {
					let primarySections = namePrimary.split(",");
					let secondarySections =
						nameSecondary?.length > 0
							? nameSecondary.split(",")
							: primarySections.length === 2
							? // WORKAROUND: to remove date prefix if there is no data to copare with
							  // converts  "January, Van" -> to just "Van"
							  [null, primarySections[1]]
							: null;

					if (secondarySections) {
						let sameSections = "";

						primarySections.forEach((section, i) => {
							if (i < secondarySections.length && section?.trim() === secondarySections[i]?.trim()) {
								if (sameSections.length > 0) sameSections = sameSections + ", ";
								sameSections = sameSections + section?.trim() ?? "";
							}
						});

						cleanupName = sameSections;
					}
				}

				if (isNumber(secondaryValue)) {
					// If at least one item has compared value, we need to show comparison section
					labelHasComparison = true;
				}

				const diffValue = isNumber(primaryValue) && isNumber(secondaryValue) ? primaryValue - secondaryValue : null;
				const diffPercentage = isNumber(diffValue)
					? diffValue === 0
						? 0
						: parseInt((secondaryValue < 0 ? (-1 * diffValue) / secondaryValue : diffValue / secondaryValue) * 1000) /
						  1000
					: null;

				const diffInverted = primaryItem.metadata?.comparisonModel === "DOWN_GOOD";
				lastValues.push({
					name: cleanupName?.length > 0 ? cleanupName : namePrimary,
					value: isNumber(primaryValue) ? valueFomatter(primaryValue) : null,
					metadata: {
						secondaryValue: !isNaN(secondaryValue) && isNumber(secondaryValue) ? valueFomatter(secondaryValue) : null,
						diffPercentage:
							!isNaN(diffPercentage) && isNumber(diffPercentage) ? valueFomatter(diffPercentage, "persents") : null,
						diffValue: !isNaN(diffValue) && isNumber(diffValue) ? valueFomatter(diffValue) : null,
						diffInverted,
						arrow: diffPercentage > 0 ? "UP" : diffPercentage < 0 ? "DOWN" : null,
						signal:
							diffPercentage > 0
								? diffInverted
									? "BAD"
									: "GOOD"
								: diffPercentage < 0
								? diffInverted
									? "GOOD"
									: "BAD"
								: null,
					},
				});
			});

			if (lastValues.length > 8) {
				labelClasses = "text-16";
			}
		} else {
			series?.forEach((item) => {
				const primaryValue = item.data?.length > 0 ? item.data[item.data.length - 1] : null;
				lastValues.push({
					name: item.name,
					value: isNumber(primaryValue) ? valueFomatter(primaryValue) : null,
				});
			});

			labelClasses = "text-28";
			if (lastValues.length > 4) {
				labelClasses = "text-24";
			}
		}
	}

	const options =
		chartType === "donut"
			? {
					colors: customColors ?? _DEFAULT_COLORS,
					chart: {
						offsetX: 15,
						animations: {
							enabled: !visuals?.animationDisabled,
							speed: 200,
						},
						id: "chart",
					},
					labels: flatCategories,
					fill: {},
					yaxis: {
						type: "category",
						labels: { formatter: labelFomatter },
					},
					legend: {
						width: 150,
						horizontalAlign: "center",
						formatter: (label) => {
							return label.length > 13 ? label.substring(0, 12) + "..." : label;
						},
					},
			  }
			: chartType === "heatmap"
			? {
					colors: customColors ?? _DEFAULT_COLORS,
					chart: {
						animations: { enabled: false },
						id: "heatmap",
						type: chartType,
						toolbar: { show: false },
						zoom: { enabled: false },
					},
					dataLabels: { enabled: false },
					markers: { size: 5, hover: { size: 9 } },
					fill: {},
					yaxis: {
						show: !heatmapData?.views?.length,
						tooltip: { enabled: false },
						labels: {
							formatter: (label) => {
								if (isNumber(label)) {
									return labelFomatter(label);
								} else {
									label = label?.replace?.("VAN-", "Van - ")?.replace?.("REEFER-", "Reefer - ");
									return label?.length > 30 ? label.substring(0, 28) + "..." : label;
								}
							},
							style: {
								fontSize: 12,
							},
						},
					},
					xaxis: {
						tooltip: { enabled: true },
						categories: categoriesLabels,
						tickAmount: chartXTickAmount,
						labels: {
							rotate: 0,
							offsetX: 0,
							offsetY: categories?.length > 1 && secondaryCategories?.length > 1 ? -3 : 0,
							style: {
								fontSize: 12,
							},
						},
					},
					legend: {
						show: false,
						offsetX: 0,
						offsetY: categories?.length > 1 && secondaryCategories?.length > 1 ? 8 : 4,
					},
			  }
			: {
					colors: customColors,
					chart: {
						animations: {
							enabled: cutOffIndex > 0 ? false : !visuals?.animationDisabled,
							speed: 200,
						},
						id: "chart",
						type: chartType,
						toolbar: { show: false },
						zoom: { enabled: false },
					},
					dataLabels: { enabled: false },
					stroke: chartStroke,
					plotOptions: { bar: { columnWidth: "80%" } },
					markers: { size: 5, hover: { size: 9 } },
					fill: {},
					yaxis: {
						type: "category",
						tooltip: { enabled: false },
						tickAmount: chartYTickAmount,
						labels: {
							formatter: labelFomatter,
							style: {
								fontSize: 12,
							},
							offsetX: 0,
							offsetY: 0,
							rotate: 0,
						},
					},
					xaxis: {
						tooltip: { enabled: true },
						categories: categoriesLabels,
						tickAmount: chartXTickAmount,
						labels: {
							rotate: 0,
							offsetX: 0,
							offsetY: categories?.length > 1 && secondaryCategories?.length > 1 ? -3 : 0,
							style: {
								fontSize: 12,
							},
						},
					},
					legend: {
						show: true,
						offsetX: 0,
						offsetY: categories?.length > 1 && secondaryCategories?.length > 1 ? 8 : 4,
					},
			  };

	if (addons) {
		// Overring default styles when we have addons
		options.dataLabels.enabled = true;
		options.stroke.width = series.map(() => 4);
		options.markers.size = series.map(() => 5);
		options.fill.type = series.map(() => "gradient");

		Object.keys(addons).forEach((index) => {
			if (index < series.length) {
				options.markers.size[index] = 0;
				options.stroke.width[index] = 2;
				options.fill.type[index] = "solid";
				if (options.colors) options.colors[index] = _ADDONS_COLORS_MAP[addons[index]?.config?.key] ?? _COLORS_MAP.BROWN;
			}
		});

		options.dataLabels.formatter = (val, opts) => {
			if (addons[opts.seriesIndex] && opts.dataPointIndex === addons[opts.seriesIndex].visibleIndex) {
				return addons[opts.seriesIndex].name + ": " + labelFomatter(val);
			}
		};
	}

	// Saving table data to refWidgetDataTable for export
	if (tableData) {
		tableData.fileName = props?.config?.label;
		refWidgetDataTable.current = tableData;
	}

	return (
		<div
			className={
				"flex flex-col w-full relative h-full " +
				(classes?.root ?? "") +
				(chartType === "table"
					? " pt-12 pb-10 "
					: chartType === "last_value"
					? " pb-10 items-center justify-center "
					: " justify-center ")
			}
		>
			{!!timeLabel && (
				<Typography className={"ml-6 w-full text-12 font-medium text-grey-500 absolute top-0 -mt-16 -mb-4 "}>
					{timeLabel}
				</Typography>
			)}
			{!!hasPrimaryDataPoints && chartType === "table" && (
				<Typography className={"pr-10 w-full text-12 font-medium text-grey-500 absolute bottom-0 text-right"}>
					{tableData?.data?.items?.length ?? 0} records
				</Typography>
			)}

			{!hasPrimaryDataPoints ? (
				<div className={"flex flex-col w-full h-full justify-center items-center"}>
					<Typography className="text-13 text-grey-600">No Data To Show</Typography>
				</div>
			) : chartType === "last_value" ? (
				<div className={"flex flex-col w-full overflow-y-scroll overflow-x-hidden space-y-14 py-10"}>
					{lastValues.map((item, i) =>
						labelChartType === "flat" ? (
							<div key={"label_" + i} className={"flex flex-row w-full items-center"}>
								{labelHasComparison && (
									<div
										className={
											"flex h-24 w-10 mr-10 " +
											" " +
											(item.metadata.signal === "GOOD"
												? " bg-green-600 "
												: item.metadata.signal === "BAD"
												? " bg-red-600 "
												: " bg-grey-500 ")
										}
									/>
								)}
								<Typography className={"block w-full text-grey-800 truncate " + labelClasses}>{item.name}</Typography>
								<Typography className={"font-bold text-grey-800 ml-28 text-right whitespace-nowrap " + labelClasses}>
									{item.value}
								</Typography>
								{labelHasComparison && (
									<Tooltip
										title={
											<Typography className={" text-white "}>
												Comparing to previous period amout of {item.metadata.secondaryValue}, where difference is{" "}
												{item.metadata.diffValue}
											</Typography>
										}
										placement="top"
									>
										<div className="flex flex-row items-center">
											<Typography
												className={
													"font-bold ml-10 min-w-80 text-right " +
													labelClasses +
													" " +
													(item.metadata.signal === "GOOD"
														? " text-green-700 "
														: item.metadata.signal === "BAD"
														? " text-red-700 "
														: " text-grey-500 ")
												}
											>
												{item.metadata.diffPercentage}
											</Typography>
											<Icon
												className={
													"text-20 font-bold " +
													(item.metadata.signal === "GOOD"
														? " text-green-600 "
														: item.metadata.signal === "BAD"
														? " text-red-600 "
														: " text-grey-400 ")
												}
											>
												{item.metadata.arrow === "UP" ? "north" : item.metadata.arrow === "DOWN" ? "south" : ""}
											</Icon>
										</div>
									</Tooltip>
								)}
							</div>
						) : (
							<div key={"label_" + i} className={"flex flex-col w-full items-center justify-center"}>
								<Typography
									className={
										"font-bold tracking-wide text-grey-800 text-right " +
										labelClasses +
										" " +
										_COLORS_TEXT[i % _COLORS_TEXT?.length]
									}
								>
									{item.value}
								</Typography>
								<Typography
									className={
										"block truncate text-grey -mt-6 opacity-70 " +
										labelClasses +
										" " +
										_COLORS_TEXT[i % _COLORS_TEXT?.length]
									}
								>
									{item.name}
								</Typography>
							</div>
						)
					)}
				</div>
			) : chartType === "table" ? (
				<div className={"overflow-y-scroll overflow-x-scroll px-2"}>
					{tableData && (
						<SmarthopTableView
							content={tableData.content}
							contentIdKey={tableData.contentIdKey}
							data={tableData.data}
							sorting={sortable}
							onOrderChange={(key) => {
								let oldOrder = sortable[key] ?? "asc";
								let newOrder = oldOrder === "asc" ? "desc" : "asc";
								let newSort = {};
								newSort[key] = newOrder;
								setSortable(newSort);
								tableData.data = { items: sortArray(tableData?.data?.items ?? [], key, newOrder) };
							}}
						/>
					)}
				</div>
			) : chartType === "heatmap" ? (
				<div className={"flex flex-row h-full justify-start overflow-y-scroll overflow-x-hidden py-6 mt-4 -mr-5"}>
					{heatmapData?.views?.length && (
						<div className="flex flex-col pt-6">
							{heatmapData.views?.map((item, i) => (
								<div
									key={"ht_header_" + i}
									className="flex flex-col justify-center text-20 border-b-1 border-grey-200 ml-4 px-8 min-w-210"
									style={{ minHeight: "48px", maxHeight: "48px" }}
								>
									{CellTypes[item.entity__view?._view_type] ? (
										CellTypes[item.entity__view._view_type]({
											item,
											cellValue: item.entity__view,
											variant: "skinny",
											viewConfig: {
												showMcNumber: item.entity__view._view_type === "carrier__view" ? true : false,
												showId: false,
												showEmail: item.entity__view._view_type === "profile__view" ? true : false,
												readOnly,
											},
										})
									) : (
										<Typography className="text-14">{item.name}</Typography>
									)}
								</div>
							))}
						</div>
					)}
					{series.length && (
						<div className="flex flex-1 w-full flex-row" style={{ marginTop: "-24px" }}>
							<ReactApexChart
								options={options}
								series={series}
								type={chartType}
								height={65 + series.length * 48 + "px"}
							/>
						</div>
					)}
				</div>
			) : (
				<ReactApexChart
					options={options}
					series={chartType === "donut" ? flatSeries : series}
					type={chartType}
					height={"100%"}
				/>
			)}
			{!!labels?.center?.length && (
				<Typography className="w-full text-center text-14 pb-28 font-medium text-black absolute mx-auto my-auto">
					{labels.center}
				</Typography>
			)}
		</div>
	);
}

export default WidgetGraph;
