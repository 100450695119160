import _ from "@lodash";
import { Parser } from "json2csv";
import JSZip from "jszip";

// Utils
import { global } from "app/services/requestUtil";
import { formatDate } from "app/main/utils/dateUtils";
import { formatCurrency } from "app/main/utils/tableUtils";

const ITEMS_PER_REQUEST = 200;
const MAX_ITEMS_PER_FILE = 1000;

/**
 * @description generate csv file
 * @param {Array.Object} fields - column names
 * @param {Array.Object} data - excel content
 * @returns
 */
const generateCSV = (fields, data) => {
	const json2csv = new Parser({ fields });
	const csv = json2csv.parse(data);
	const mime = "text/csv";
	return { content: csv, mime };
};

/**
 * @description download file
 * @param {Response} data - file data
 * @param {String} name - file name
 * @param {String} mime - mime type
 */
const downloadFile = (data, name, mime) => {
	let buffer = Buffer.from(data);
	let blob = new Blob([buffer], { type: mime });
	let csvURL = window.URL.createObjectURL(blob);
	let link = document.createElement("a");
	link.href = csvURL;
	link.setAttribute("download", name);
	link.click();
};

const uploadFile = (fileType, callback) => {
	const inputElement = document.createElement("input");
	inputElement.setAttribute("type", "file");
	inputElement.setAttribute("accept", fileType);
	inputElement.onchange = (event) => {
		const data = new FormData();
		data.append("file", event?.target?.files[0]);
		data.append("type", fileType);

		callback(data);
	};

	inputElement.click();
};

/**
 * @description build report form config with date filter
 * @param {String} url
 * @param {String} label
 * @param {String} name
 * @param {String} mime
 */
const reportFiltersForm = (url, label, name, mime = "text/csv", config = {}) => ({
	urlPOST: url,
	idKey: "_id",
	triggerEvent: "",
	listenEvent: "",
	header: { create: { label } },
	messages: { create: { success: "Report generated." } },
	content: {
		create: {
			items: [
				{
					key: "report_start_date",
					type: "picker",
					label: config.startDateTitle ?? "Start Date",
					translate: "",
					required: true,
					...(config.startDefaultValue ? { defaultValue: config.startDefaultValue } : {}),
					field: {
						classes: {},
						variant: undefined,
					},
					picker: {
						disableFuture: false,
						notClearable: config.notClearable ?? false,
						type: config.type ?? "date",
						classes: {},
					},
				},
				{
					key: "report_end_date",
					type: "picker",
					label: config.endDateTitle ?? "End Date",
					translate: "",
					required: true,
					...(config.endDefaultValue ? { defaultValue: config.endDefaultValue } : {}),
					field: {
						classes: {},
						variant: undefined,
					},
					picker: {
						disableFuture: false,
						notClearable: config.notClearable ?? false,
						type: config.type ?? "date",
						classes: {},
					},
				},
				{ type: "action", action: "SUBMIT", ...(config.buttonConfig ? { button: config.buttonConfig } : {}) },
			],
			onFinished: (data) => {
				downloadFile(data, name, mime);
			},
		},
	},
});

const viewOrDownloadFiles = ({ dataIds, fileId, download = false, nativeMobile = false }) => {
	let url = "/api/profile/carriers/:carrierId/files/";
	Object.keys(dataIds).forEach((key) => {
		url = url.replace(":" + key, dataIds[key]);
	});

	const filePreviewUrl = `${global.SERVER_NAME}${url}${fileId}?disposition=${
		download ? "attachment" : "inline"
	}&token=${localStorage.getItem("tokenSmarthop")}`;

	if (nativeMobile) {
		window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: "FILE_PREVIEW", url: filePreviewUrl }));
	} else {
		window.open(filePreviewUrl, "_blank");
	}
};

/**
 * @description create a zip file
 * @param {Array of Buffer} files
 * @param {String} name - name of zip file
 */
const createZipFiles = (files, name) => {
	const zip = new JSZip();
	for (let file of files) {
		zip.file(file.name, file?.buffer?.data);
	}

	zip.generateAsync({ type: "blob" }).then(function (content) {
		let csvURL = window.URL.createObjectURL(content);
		let link = document.createElement("a");
		link.href = csvURL;
		link.setAttribute("download", name);
		link.click();
	});
};

/**
 * @description build request for export
 * @param {Object} notUrlDataIds - data ids
 * @param {Object} requestData - request data
 * @param {Number} offset - offset
 * @param {Number} limit - limit
 * @returns {Object} request
 */
const buildRequest = ({ notUrlDataIds, requestData, offset, limit }) => {
	let processedRequestFilters = {};
	Object.keys(requestData?.filters ?? {}).forEach((key) => {
		if (!key.includes("__view") && key !== "search") {
			processedRequestFilters[key] = requestData.filters[key];
		}
	});
	return {
		offset,
		limit,
		...notUrlDataIds,
		sortOrder: requestData.sort?.dir,
		sortBy: requestData.sort?.key,
		search: requestData.filters?.search ?? "",
		filters: { ...(processedRequestFilters ?? {}), ...(requestData.staticFilters ?? {}) },
		groups: { ...(requestData.groups ?? {}) },
		settings: requestData.settings ?? {},
		...(requestData.staticParams ?? {}),
	};
};

/**
 * @description remove duplicated items
 * @param {Array} array - array of items
 * @returns {Array} array of items
 */
const uniqueObjectArrayById = (array) =>
	array.filter((item, index, self) => index === self.findIndex((t) => t._id === item._id));

/**
 * @description find correct field
 * @param {Object} item - item
 * @returns {Object} item
 */
const findCorrectField = (item) => {
	let value = item;
	if (item?.label) {
		value = item.label;
	} else if (item?.reason) {
		value = item.reason;
	}
	return value;
};

const formatValue = (value, type) => {
	if (type === "currency") {
		return formatCurrency(value);
	}
	if (!value && type !== "text") {
		return "";
	}
	if (type === "date") {
		return formatDate(value);
	}
	if (_.isString(value) || _.isNumber(value)) {
		return value;
	}
	if (_.isBoolean(value)) {
		return value ? "Yes" : "No";
	}

	return "";
};

export {
	downloadFile,
	reportFiltersForm,
	uploadFile,
	viewOrDownloadFiles,
	generateCSV,
	createZipFiles,
	buildRequest,
	uniqueObjectArrayById,
	findCorrectField,
	formatValue,
	ITEMS_PER_REQUEST,
	MAX_ITEMS_PER_FILE,
};
