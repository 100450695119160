import moment from "moment";
import React from "react";
import { Typography, Button } from "@material-ui/core";

import { useEffect, useState, useMemo } from "react";
import {
	getDatapointsSeries,
	getCarrierDatapointsSeries,
	getCarrierUserDatapointsSeries,
	getAccountProfileDatapointsSeries,
	getAccountDatapointsSeries,
	getTruckDatapointsSeries,
	getUserDatapointsSeries,
} from "app/services/analyticsServices";

import WidgetGraph from "./WidgetGraph";

function WidgetDynamic(props) {
	const config = props.config;
	const visuals = props.visuals;
	const overrides = props.overrides;
	const mutable = props.mutable;
	const carrierId = props.dataIds?.carrierId;
	const userId = props.dataIds?.userId;
	const accountId = props.dataIds?.accountId;
	const profileId = props.dataIds?.profileId;
	const truckId = props.dataIds?.truckId;
	const onChangeHeight = props.onChangeHeight;
	const refWidgetDataTable = props.refWidgetDataTable;

	const overrideMainWindow = overrides?.selectedWindow?.main;
	const overrideSecondayWindow = overrides?.selectedWindow?.secondary;
	const overridePeriod = overrides?.selectedWindow?.period;

	const [globalRevision, setGlobalRevision] = useState(0);
	const [localRevision, setLocalRevision] = useState(0);
	const [error, setError] = useState(null);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [emptyConfig, setEmptyConfig] = useState(false);
	const [currentConfig, setCurrentConfig] = useState(false);
	const [currentIds, setCurrentIds] = useState(null);

	let overrideDisabled = false;
	if (
		!!config.aggregationEnforceWidowType &&
		((!!overrideMainWindow &&
			!!config.aggregationWindowMain &&
			config.aggregationWindowMain.split("_")[0] !== overrideMainWindow.split("_")[0]) ||
			(!!overrideSecondayWindow &&
				!!config.aggregationWindowSecondary &&
				config.aggregationWindowSecondary.split("_")[0] !== overrideSecondayWindow.split("_")[0]))
	) {
		overrideDisabled = true;
	}

	if (
		!!config.aggregationEnforcePeriodType &&
		!!overridePeriod &&
		!!config.aggregationPeriod &&
		config.aggregationPeriod !== overridePeriod
	) {
		overrideDisabled = true;
	}

	const updatedConfig = useMemo(() => {
		const updatedConfig = { ...config };
		if (overrideDisabled) {
			return updatedConfig;
		}

		if (
			overrideMainWindow?.length > 0 &&
			updatedConfig.aggregationWindowMain?.length > 0 &&
			updatedConfig.aggregationWindowMain !== "__NOT_SELECTED__"
		) {
			updatedConfig.aggregationWindowMain = overrideMainWindow;
		}
		if (
			overrideSecondayWindow?.length > 0 &&
			updatedConfig.aggregationWindowSecondary?.length > 0 &&
			updatedConfig.aggregationWindowSecondary !== "__NOT_SELECTED__"
		) {
			updatedConfig.aggregationWindowSecondary = overrideSecondayWindow;
		}
		if (overridePeriod?.length > 0 && updatedConfig.aggregationPeriod !== "ALL") {
			updatedConfig.aggregationPeriod = overridePeriod;
		}
		updatedConfig.boardAccessType = props.boardAccessType;
		return updatedConfig;
	}, [config, overrideMainWindow, overrideSecondayWindow, overridePeriod, overrideDisabled, props.boardAccessType]);

	useEffect(() => {
		const emptyConfig = !config?.aggregationPeriod || !config?.aggregationWindowMain || !config?.metrics?.length;
		if (
			emptyConfig ||
			(currentConfig &&
				JSON.stringify(config) === JSON.stringify(currentConfig) &&
				globalRevision === props.revision &&
				(currentIds?.accountId ?? "") === (accountId ?? "") &&
				(currentIds?.carrierId ?? "") === (carrierId ?? "") &&
				(currentIds?.truckId ?? "") === (truckId ?? "") &&
				(currentIds?.userId ?? "") === (userId ?? ""))
		) {
			setEmptyConfig(emptyConfig);
			setLoading(false);
			return;
		}

		setGlobalRevision(props.revision);
		let stopped = false;
		(async () => {
			try {
				setEmptyConfig(false);
				setLoading(true);

				// Adding initial loading delay to get top widget in a bord to load
				// data first and avoid other request blocking top widgets loading
				const start = moment.now();
				if (visuals?.initDelay > 1100) {
					await new Promise((r) => setTimeout(r, visuals.initDelay / 10));
				}

				let data;
				if (props.boardAccessType === "USER" || props.boardAccessType === "USER_CREATOR") {
					updatedConfig.truckId = truckId;
					if (!!carrierId) {
						data = await getCarrierUserDatapointsSeries(carrierId, userId, updatedConfig, !!mutable);
					} else {
						data = await getUserDatapointsSeries(userId, updatedConfig, !!mutable);
					}
				} else if (!!carrierId) {
					if (userId) {
						// If user provided we will select only that user data (if dashboard is based on truck
						// generated data points we will show data based on truck assignemnts, for example driver
						// would see their trucks performance and investors their trucks performance)
						data = await getCarrierUserDatapointsSeries(carrierId, userId, updatedConfig, !!mutable);
					} else if (truckId) {
						data = await getTruckDatapointsSeries(carrierId, truckId, updatedConfig, !!mutable);
					} else {
						data = await getCarrierDatapointsSeries(carrierId, updatedConfig, !!mutable);
					}
				} else if (!!accountId) {
					if (profileId) {
						data = await getAccountProfileDatapointsSeries(accountId, profileId, updatedConfig, !!mutable);
					} else {
						data = await getAccountDatapointsSeries(accountId, updatedConfig, !!mutable);
					}
				} else {
					data = await getDatapointsSeries(updatedConfig, !!mutable);
				}

				// Adding post request delay to sync vilualization of widgets by groups
				// top would be dispalayed first and that would avoid animations conflicts
				const time = moment.now() - start;
				if (visuals?.initDelay > time) {
					await new Promise((r) => setTimeout(r, visuals.initDelay - time));
				}

				if (stopped) return;
				setCurrentIds({ carrierId, userId, truckId });

				if (!data?.categories || !data?.series) {
					console.error("[WidgetContent] Invalid data format", data);
					setError("Invalid Data Format");
					setCurrentConfig(null);
				} else {
					if (config?.graphHeightAuto && config?.graphType === "HEATMAP" && data?.series?.length > 0) {
						let height = parseInt((data?.series?.length + 1) / 3 + 1);
						if (height < 2) height = 2;
						onChangeHeight(height);
					} else if (config?.graphHeightAuto && config?.graphType === "TABLE" && data?.series?.length > 0) {
						const mainColumnField = config?.graphTableMainColumn.toLowerCase();
						const rowsIds = [];
						data?.series?.forEach((item) => {
							if (item.metadata?.type === "SECONDARY") {
								return;
							}
							const mainColumnId =
								mainColumnField === "link" ? item.metadata.links?.[0] : item.metadata[mainColumnField];
							if (!rowsIds.includes(mainColumnId)) {
								rowsIds.push(mainColumnId);
							}
						});
						let height = parseInt((rowsIds?.length + 1) / 3 + 1);
						if (height > 2) {
							onChangeHeight(height);
						}
					}
					setError(null);
					setData(data);
					setCurrentConfig(config);
				}
				setLoading(false);
			} catch (e) {
				console.error("[WidgetContent] Failed to load data", e);
				const message = e.message ?? e.errors?.[0]?.message;
				if (message.includes("inconsistent series sizes")) {
					// Showing to user that there is no data to show
					console.error("[WidgetContent] Failed to fetch data", message);
					setError(null);
					setData({});
					setCurrentConfig(config);
				} else {
					setError(message);
					setCurrentConfig(null);
				}
				setLoading(false);
			}
		})();

		return () => {
			stopped = true;
		};
		// eslint-disable-next-line
	}, [updatedConfig, localRevision, carrierId, userId, props.revision, truckId]);

	if (error) {
		return (
			<div className="flex flex-col h-full items-center justify-center">
				<Typography className="w-full text-center" color="error">
					{error}
				</Typography>
				<Button
					className="flex py-4 bg-red text-white mt-3"
					variant="contained"
					onClick={() => {
						setCurrentConfig(null);
						setError(null);
						setLoading(true);
						setLocalRevision(localRevision + 1);
					}}
				>
					Retry
				</Button>
			</div>
		);
	} else if (loading) {
		return (
			<div className="flex flex-col h-full items-center justify-center">
				<Typography className="w-full text-center text-grey-600">Loading..</Typography>
			</div>
		);
	} else if (emptyConfig) {
		return (
			<div className="flex flex-col h-full items-center justify-center">
				<Typography className="w-full text-center text-grey-600">Config Required</Typography>
			</div>
		);
	}

	return (
		<WidgetGraph
			data={data}
			refWidgetDataTable={refWidgetDataTable}
			config={updatedConfig}
			visuals={visuals}
			classes={{ root: overrideDisabled ? "opacity-30" : "" }}
			labels={{ center: overrideDisabled ? "Selected Time Not Supported" : null }}
		/>
	);
}

export default WidgetDynamic;
